import React from 'react';
import HomeCode from '../components/pages/HOME PAGE/HomeCode';

const Home = () => {
  return (
    <>
      <HomeCode />
    </>
  )
}

export default Home