import {React } from 'react';

import '../../Tools/Animations.scss';

import './Home.scss';

import {Title} from '../../data/Imagedata';
import {homebg,homebg_mob,aboutuscards,Clutchness,Sam,} from '../../data/Imagedata'; /*Videos Import*/
import {Roadmap,roadmap_modile,porm_desktop,porm_mob} from '../../data/Imagedata';/*Roadmap Import*/

import {Elephantgif,elephant2,elephant3,elephant4,elephant5,elephant6,elephant7,
  elephant8,elephant10,elephant11,elephant12,elephant14,elephant15,
  elephant16,elephant18,elephant20,elephant19,} from '../../data/Imagedata';/*Art & Scroll*/


const HomeCode = () => {
 
return (

<main style={{ overflow:"hidden"}} className='snap-y snap-mandatory'>
{/* Author: Akhil Thej Reddy| Website: www.cyberspacedigital.in | Looking for a website please contact us.*/}

{/* Home - Banner */}
<section>
       <div
        className="my-section"
        style={{
          backgroundImage: `url(${homebg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}>

        </div>
      <div
        className="my-section my-section-small"
        style={{
          backgroundImage: `url(${homebg_mob})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",}}>

<style>{`
@media (max-width: 768px) {
  .my-section {
    display: none;
  }
  .my-section-small {
    display: block;
  }
  h1{
    font-size: 20px;
  }
}

/* Show the large image on screens wider than 768px */
@media (min-width: 769px) {
  .my-section {
    display: block;
  }
  .my-section-small {
    display: none;
  }
}
      `}</style>
      </div>
</section>



{/* Title -RTS */}
<section class="mt-10 snap-start flex items-center justify-center min-h-screen glass" >
<Title />
</section>


{/* Title -Welcome */}
<section>
  <div className="snap-start flex flex-col items-center justify-center h-full min-h-screen">
    <h1 className="welcomeheard  font-bold text-white text-center">Welcome to The Herd</h1>
  </div>
</section>


{/* Title -poem */}
<section className='snap-start my-10'>
       <div
        className="my-section"
        style={{
          backgroundImage: `url(${porm_desktop})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}>

        </div>
      <div
        className="my-section my-section-small"
        style={{
          backgroundImage: `url(${porm_mob})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          minHeight: "100vh",}}>

<style>{`
.roadmaptitle{
  font-family:Aniron_bold;
  font-size: 50px;
}
@media (max-width: 768px) {
  .roadmaptitle{
    font-size: 20px;
  }
  .my-section {
    display: none;
  }
  .my-section-small {
    display: block;
  }
  h1{
    font-size: 20px;
  }
}

/* Show the large image on screens wider than 768px */
@media (min-width: 769px) {
  .my-section {
    display: block;
  }
  .my-section-small {
    display: none;
  }
}
          `}</style>
</div>
</section>


{/* Art & Scroll */}
<section className='home6 snap-start'  >  
  <section className='home6_1'>
      <article>
    <div>
      <ul>
      <li>
          <img src={elephant2} />
        </li>
        <li>
          <img src={elephant3} />
        </li>
        <li>
          <img src={elephant4} />
        </li>
        <li>
          <img src={elephant5} />
        </li>
        
      </ul>
    </div>
    <div>
      <ul>
        <li>
          <img src={elephant6} />
        </li>
        <li>
          <img src={elephant7} />
        </li>
        <li>
          <img src={elephant8} />
        </li>
        <li>
          <img src={elephant10} />
        </li>
      </ul>
      </div>
      <div>
      <ul>
        <li>
          <img src={elephant11} />
        </li>
        <li>
          <img src={elephant12} />
        </li>
        <li>
          <img src={elephant14} />
        </li>
        <li>
          <img src={elephant15} />
        </li>
      </ul>
      </div>
      <div>
      <ul>
        <li>
          <img src={elephant16} />
        </li>
        <li>
          <img src={elephant18} />
        </li>
        <li>
          <img src={elephant19} />
        </li>
        <li>
          <img src={elephant20} />
        </li>
      </ul>
      </div>
    </article>
  </section>

  {/* ART */}
  <section className='snap-start '>
    <div className="container px-10 sm:px-40 py-10 mx-auto">
      
      <div className="mt-8 lg:-mx-6 lg:flex lg:items-center">
        <img
          className="object-cover w-full lg:mx-6 lg:w-1/2 rounded-xl h-72 lg:h-96"
          src={Elephantgif}
          alt=""
        />
        <div className="mt-6 lg:w-1/2 lg:mt-0 lg:mx-6 ">
        <h1 className="text-3xl font-semibold text-white capitalize lg:text-4xl ">
        About Us
      </h1>
          <p className="mt-3 text-sm text-white md:text-xl">
          Raging Tusker Society is a collection of 7000 NFTs, which will be your social identity within the ecosystem.                 
Our goal is to create a community that has holders from all walks of life, across multiple chains and has one of the 
most amazing communities anywhere (we’ll need your help on this one), while providing epic profile pictures, while also 
bringing the fun back to NFTs.
          </p>
          <div className="flex items-center mt-6">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='home6_1'>
      <article>
    <div>
      <ul>
      <li>
          <img src={elephant20} />
        </li>
        <li>
          <img src={elephant19} />
        </li>
        <li>
          <img src={elephant18} />
        </li>
        <li>
          <img src={elephant16} />
        </li>
        
      </ul>
    </div>
    <div>
      <ul>
        <li>
          <img src={elephant11 } />
        </li>
        <li>
          <img src={elephant12} />
        </li>
        <li>
          <img src={elephant14} />
        </li>
        <li>
          <img src={elephant15} />
        </li>
      </ul>
      </div>
      <div>
      <ul>
        <li>
          <img src={elephant10} />
        </li>
        <li>
          <img src={elephant8} />
        </li>
        <li>
          <img src={elephant7} />
        </li>
        <li>
          <img src={elephant6} />
        </li>
      </ul>
      </div>
      <div>
      <ul>
        <li>
          <img src={elephant5} />
        </li>
        <li>
          <img src={elephant4} />
        </li>
        <li>
          <img src={elephant3} />
        </li>
        <li>
          <img src={elephant2} />
        </li>
      </ul>
      </div>
    </article>
  </section>
 
</section>


{/* Vision */}
<section className="Vision flex items-center justify-center glass snap-start mt-10" >
  <div className="mx-auto max-w-[43rem]">
    <div className="text-center">
    
      <h2 className="mx-0 sm:-mt-40  text-[3.5rem] font-bold leading-[4rem] tracking-tight text-white">
      Vision
      </h2>
      <p className="mx-10   sm:text-[3.5rem] leading-relaxed text-slate-400 text-white">
      To put an end to the fud culture on cardano,
      and help bring forth a better ecosystem,<br/>
      where teams and communities work together,
      in unison, lifting each other up as we grow.<br/> 
      Because This is The Way.
      We are an art and community oriented project,<br/>
      but will always look for ways to reward our 
      holders and make everyone a fucking winner. <br/>
      There will be benefits to being a member of The Herd,
      and we will add and improve on things as we <br/>
      grow and adapt to this fast-paced and 
      evolving market. <br/>
      We look forward to doing this with you.
      </p>
    </div>
  </div>
  <style>
        {`
        .Vision{
          min-height:80vh
        }
        .Vision h2{  
          
          color: #ffff;
          font-size: 150px;
          }
          @media only screen and (max-width: 768px) {
            .Vision h2{
              font-size: 50px;

        `}
      </style>
</section>


{/*Roadmap */}
<section className='snap-start mt-10' >
       <div
        className="my-section"
        style={{
          backgroundImage: `url(${Roadmap})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}>

        </div>
      <div
        className="my-section my-section-small"
        style={{
          backgroundImage: `url(${roadmap_modile})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
          minHeight: "100vh",}}>

<style>{`
@media (max-width: 768px) {
  .my-section {
    display: none;
  }
  .my-section-small {
    display: block;
  }
  h1{
    font-size: 20px;
  }
}

/* Show the large image on screens wider than 768px */
@media (min-width: 769px) {
  .my-section {
    display: block;
  }
  .my-section-small {
    display: none;
  }
}
      `}</style>
      </div>
</section>

   

{/* Team */}
<section className='Team glass snap-start my-10'>
  <div className=" justify-center my-auto items-center">
  <h2 class="py-10  text-2xl sm:text-6xl font-extrabold text-center drop-shadow-lg shadow-black text-white"> TEAM</h2>
  <div className="flex justify-center items-center pb-10 mx-5">

    <div className="px-10 md:px-4 md:grid md:grid-cols-2 lg:grid-cols-2 gap-5 space-y-4 md:space-y-0">

    <a href="https://twitter.com/the__clutchness">
      <div className="max-w-sm px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-105 transition duration-500" 
       style={{ backgroundImage: `url(${aboutuscards})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}} >
        <h3 className="mb-3 text-sm text-center sm:text-xl font-bold text-white font-">
        Founder,CEO, Marketing Head
        </h3>
        <div className="relative">
          <img
            className="w-full rounded-xl"
            src={Clutchness}
            alt="Clutchness"/>
          </div>
        <h1 className="mt-4  text-white text-sm text-center sm:text-2xl font-bold cursor-pointer">
        Clutchness
        </h1>
        <div class="sociallogo">
        <a className='px-2' href="https://twitter.com/the__clutchness" target="_blank"><svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.3048C13.4852 1.5253 12.9321 1.67428 12.3507 1.74162C12.9506 1.39466 13.3994 0.848596 13.6134 0.205309C13.0498 0.528893 12.4329 0.756661 11.7896 0.878711C11.357 0.432278 10.784 0.136375 10.1595 0.0369432C9.53511 -0.0624891 8.89419 0.040112 8.33627 0.328817C7.77835 0.617523 7.33466 1.07618 7.07408 1.63358C6.81351 2.19098 6.75062 2.81593 6.89518 3.41142C5.75309 3.35599 4.63581 3.06908 3.61587 2.56931C2.59593 2.06954 1.69611 1.36807 0.974808 0.510426C0.728177 0.921618 0.586365 1.39836 0.586365 1.9061C0.58609 2.36317 0.702548 2.81325 0.925407 3.21639C1.14827 3.61953 1.47064 3.96327 1.86391 4.21711C1.40782 4.20309 0.961782 4.08397 0.562935 3.86969V3.90544C0.562889 4.54651 0.792322 5.16785 1.2123 5.66403C1.63229 6.16021 2.21695 6.50068 2.86708 6.62765C2.44398 6.73833 2.00038 6.75463 1.56981 6.67533C1.75324 7.22693 2.11054 7.70928 2.5917 8.05486C3.07286 8.40044 3.65378 8.59195 4.25315 8.60257C3.23569 9.37454 1.97914 9.79329 0.685634 9.79145C0.456502 9.79151 0.227564 9.77857 0 9.75271C1.31299 10.5686 2.8414 11.0017 4.40236 11C9.68643 11 12.5751 6.77008 12.5751 3.10153C12.5751 2.98235 12.572 2.86197 12.5665 2.74278C13.1283 2.35005 13.6134 1.86372 13.9988 1.30659L14 1.3048Z" fill="white"></path></svg></a>
        </div>      
      </div>
      </a>
      <a href="https://twitter.com/sam_ada1">

      <div className="mt-3 sm:mt-0 max-w-sm  px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-105 transition duration-500"
      style={{ backgroundImage: `url(${aboutuscards})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        <h3 className="mb-3 text-sm text-center sm:text-xl font-bold text-white">
        (Co-founder, Creative Head)
        </h3>
        <div className="relative">
          <img
            className="w-full rounded-xl"
            src={Sam}
            alt="Colors"/>
        </div>
        <h1 className=" items-center text-sm text-center sm:text-2xl mt-4 text-white font-bold cursor-pointer">
        Sam
        </h1>
        <div class="sociallogo">
        <a className='px-2' href="https://twitter.com/sam_ada1" target="_blank"><svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 1.3048C13.4852 1.5253 12.9321 1.67428 12.3507 1.74162C12.9506 1.39466 13.3994 0.848596 13.6134 0.205309C13.0498 0.528893 12.4329 0.756661 11.7896 0.878711C11.357 0.432278 10.784 0.136375 10.1595 0.0369432C9.53511 -0.0624891 8.89419 0.040112 8.33627 0.328817C7.77835 0.617523 7.33466 1.07618 7.07408 1.63358C6.81351 2.19098 6.75062 2.81593 6.89518 3.41142C5.75309 3.35599 4.63581 3.06908 3.61587 2.56931C2.59593 2.06954 1.69611 1.36807 0.974808 0.510426C0.728177 0.921618 0.586365 1.39836 0.586365 1.9061C0.58609 2.36317 0.702548 2.81325 0.925407 3.21639C1.14827 3.61953 1.47064 3.96327 1.86391 4.21711C1.40782 4.20309 0.961782 4.08397 0.562935 3.86969V3.90544C0.562889 4.54651 0.792322 5.16785 1.2123 5.66403C1.63229 6.16021 2.21695 6.50068 2.86708 6.62765C2.44398 6.73833 2.00038 6.75463 1.56981 6.67533C1.75324 7.22693 2.11054 7.70928 2.5917 8.05486C3.07286 8.40044 3.65378 8.59195 4.25315 8.60257C3.23569 9.37454 1.97914 9.79329 0.685634 9.79145C0.456502 9.79151 0.227564 9.77857 0 9.75271C1.31299 10.5686 2.8414 11.0017 4.40236 11C9.68643 11 12.5751 6.77008 12.5751 3.10153C12.5751 2.98235 12.572 2.86197 12.5665 2.74278C13.1283 2.35005 13.6134 1.86372 13.9988 1.30659L14 1.3048Z" fill="white"></path></svg></a>
        </div> 
    </div></a>
    </div>
  </div>
  </div>
  <style>
        {`
    .Team{
      min-height:100vh; display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;}

      .sociallogo {
        display: flex;
        justify-content: center; }

   `}
      </style>
</section>



{/*FAQ */}
<section className='FAQ text-center snap-start'>     
  <div className="mx-10 my-10" >
    <div className="faqcontent items-center ">
      <h2 className=" font-bold  tracking-tight">FAQ</h2>
    </div>
    <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto">
      <div className="py-5">
        <details className="group">
          <summary className=" flex justify-between items-center font-medium cursor-pointer list-none">
            <span>Which blockchain will you be minting on?</span>
            <span className="transition group-open:rotate-180">
              <svg
                fill="none"
                height={24}
                shapeRendering="geometricPrecision"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width={24}>
                <path d="M6 9l6 6 6-6" />
              </svg>
            </span>
          </summary>
          <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
          On Cardano
          </p>
        </details>
      </div>
      <div className="py-5">
        <details className="group">
          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
            <span>Why Cardano?</span>
            <span className="transition group-open:rotate-180">
              <svg
                fill="none"
                height={24}
                shapeRendering="geometricPrecision"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width={24}
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </span>
          </summary>
          <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
          Cardano is what helped us to get to where we are, and we’d like to give back to this ecosystem, by supporting builders on the chain, and also doing what we can to onboard new users to the network.
          </p>
        </details>
      </div>
      <div className="py-5">
        <details className="group">
          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
            <span> Wen mint?</span>
            <span className="transition group-open:rotate-180">
              <svg
                fill="none"
                height={24}
                shapeRendering="geometricPrecision"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width={24}
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </span>
          </summary>
          <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
             24th May 2023
          </p>
        </details>
      </div>
      <div className="py-5">
        <details className="group">
          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
            <span> What is the mint price?</span>
            <span className="transition group-open:rotate-180">
              <svg
                fill="none"
                height={24}
                shapeRendering="geometricPrecision"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width={24}
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </span>
          </summary>
          <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
          TBA
          </p>
        </details>
      </div>
      <div className="py-5">
        <details className="group">
          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
            <span>What is the collection size?</span>
            <span className="transition group-open:rotate-180">
              <svg
                fill="none"
                height={24}
                shapeRendering="geometricPrecision"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width={24}
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </span>
          </summary>
          <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
          7000
          </p>
        </details>
      </div>
      <div className="py-5">
        <details className="group">
          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
            <span>Which wallets are compatitble to mint?</span>
            <span className="transition group-open:rotate-180">
              <svg
                fill="none"
                height={24}
                shapeRendering="geometricPrecision"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                width={24}
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </span>
          </summary>
          <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
          We’d recommend using a nami or eternl wallet, but you may also use other wallets such as flint or gero wallet.
          DO NOT use a centralised exchange for the mint, or to buy or store NFTs.
          </p>
        </details>
      </div>
      
   
    </div>
 
  </div>
</section>

{/*Join us */}
<section class="flex flex-col items-center justify-center ">
  <h2 class="text-white text-center mb-4 ">Join The Herd Today</h2>
  <div class="flex justify-center">
    <a href="https://discord.com/invite/QbPDqUe2md" >
      <button class="bg-transparent hover:bg-white hover:text-black text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded mr-2">
        Discord
      </button>
    </a>
    <a href="https://twitter.com/RagingTuskers">
      <button class="bg-transparent hover:bg-white hover:text-black text-white font-semibold py-2 px-4 border border-white hover:border-transparent rounded ml-2">
        Twitter
      </button>
    </a>
  </div>
</section>



    </main>
  )
}



export default HomeCode