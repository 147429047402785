import React, { useState } from "react";
import {  Routes, Route, useLocation  } from 'react-router-dom';


import {Navbar,Footer,PrivacyPolicy,TermsnConditions,Disclaimer,Error404,Home,} from './routes/Routesmap.js';

import Notification from "./components/Tools/Notifications";        
        
export function App() {

  const location= useLocation();
  window.scrollTo(0, 0); /*---ResetPagelocation---*/

  /*---LogoPreLoager---*/
  const [setLoading] = useState(true);
  const preloader = document.getElementById("preloader");
  if (preloader) {
    setTimeout(() => {
      preloader.style.display = "none";
      setLoading(false);
    }, 1000);
  };

  
  return (
  <>
  <Navbar />
      <Notification/>
      <Routes location ={location} key={location.pathname}>
        
          <Route path='/' element={<Home />} />
          <Route path='/*' element={<Error404 />} />


          <Route path='/privacypolicy' element={<PrivacyPolicy />} /> 
          <Route path='/terms&conditions' element={<TermsnConditions />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
         


      </Routes>


  <Footer />   
</>
    
  );

}

export default App;